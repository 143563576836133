<template>
  <v-app>
    <v-banner class="lavBanner" single-line height="400" >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/ARhome-banner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  虚拟与现实结合的趣味营销体验
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  海量模板+趣味营销玩法 助力企业指数级提升营销指标
                </v-card-title>
                <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                    @click.stop="handleBannerBtn()"
                  >
                    立即体验
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- AR互动游戏 -->
    <div class="d-flex justify-center pb-5 warp1" style="background: #F5F9FD;">
      <v-card flat class="max-width-1200"  color="transparent">
        <div class="public-title">
          <p>{{ data.modular.titleBg }}</p>
          <div>
            <span>{{ data.modular.title }}</span>
            <span>{{ data.modular.subtitle }}</span>
          </div>
        </div>

        <v-tabs class="g-tabs justify-center d-flex pt-6" v-model="tab" background-color="transparent" style="background: #F5F9FD;">
          <v-tabs-slider color="#0084FF"></v-tabs-slider>
          <v-tab v-for="item in data.advantage" :key="item.title" >
            {{item.tab}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background: #F5F9FD;">
          <v-tab-item
            v-for="(items,index) in data.advantage"
            :key="index"
            :transition="false"
          >
          <v-card  flat class="itemStyle d-flex flex-no-wrap justify-center align-center"  color="transparent">
            <transition  name="slide-fade" appear>
              <div v-show="tab == index">
                <v-img 
                  class="tabImg"
                  max-height="260" max-width="400"
                  min-width="400" min-height="260"
                  :src="items.src"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              </transition>
            <transition name="slide-fade-reverse" appear v-show="tab == index">
                <v-card width="392" flat class="pa-0" v-show="tab == index"  color="transparent">
                  <v-card-title v-text="items.titleList.title" class="tabTitle font-size-24 pa-0 "></v-card-title>
                  <v-card-text v-text="items.titleList.content" class="tabNew font-size-14 pa-0 mt-4"></v-card-text>
                  <div v-for="(Btn,ind) in items.titleBtn" :key="ind">
                     <v-card-text v-text="Btn" class="tabNew font-size-14 pa-0" :style="ind===0?{'marginTop':'22px'}:''"></v-card-text>
                  </div>
                </v-card>
              </transition>
              
          </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <v-container style="margin-top: 10px">
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">模板编辑方式<br/>轻松生成趣味互动内容</v-card-text>
          <v-card-text class="tip" style="margin-top:21px">简单操作即可完成内容制作，减低使用门槛</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/ARhome2-1-1.png" /></div>
      </div>
      <div class="box pa-0">
            <div class="left"><img src="https://h5.ophyer.cn/official_website/other/ARhome2-2.png" /></div>
            <div class="right">
              <v-card-text class="title">游戏界面灵活配置<br/>提升品牌影响力</v-card-text>
              <v-card-text class="tip">界面元素及内容均支持自定义，让用户在参与游戏的同时<br/>加深品牌印象</v-card-text>
            </div>
      </div>
    </v-container>
    <!-- 客户案例 -->
    <!--<v-container class="max-width-1200 warp3" style="margin: 0 auto">-->
    <!--  <div class="public-title">-->
    <!--    <p>{{ data.case.titleBg }}</p>-->
    <!--    <div>-->
    <!--      <span>{{ data.case.title }}</span>-->
    <!--      <span>{{ data.case.subtitle }}</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <v-row class="mt-9">-->
    <!--    <v-col-->
    <!--      v-for="(info, infoIndex) in data.case.anli"-->
    <!--      :key="infoIndex"-->
    <!--      cols="3"-->
    <!--    >-->
    <!--      <v-card-->
    <!--        class="mx-auto item-card"-->
    <!--        max-width="240"-->
    <!--        max-height="410"-->
    <!--        @click="goCaseDetail(info)"-->
    <!--      >-->
    <!--        <v-img-->
    <!--          max-height="340"-->
    <!--          class="tran-sec"-->
    <!--          :src="info.coverImg"-->
    <!--          :aspect-ratio="9 / 16"-->
    <!--        >-->
    <!--        </v-img>-->
    <!--        <div class="text-box">-->
    <!--          <v-card-text-->
    <!--            class="font-size-14 sizeStyle"-->
    <!--            v-text="info.itemTitle"-->
    <!--          ></v-card-text>-->
    <!--        </div>-->
    <!--      </v-card>-->
    <!--    </v-col>-->
    <!--  </v-row>-->
    <!--</v-container>-->
    <!-- 我们的优势 -->
    <v-container class="max-width-1200 warp4" style="margin: 0 auto">
      <div class="public-title">
        <p>{{ data.ARadvantage.titleBg }}</p>
        <div>
          <span>{{ data.ARadvantage.title }}</span>
          <span>{{ data.ARadvantage.subtitle }}</span>
        </div>
      </div>
      <v-row class="justify-center mt-9 mb-11">
          <v-col cols="3" v-for="(item,index) in data.ARadvantage.ProductCard" :key="index" class="d-flex child-flex">
                <v-card elevation="0">
                    <div>
                      <div class="d-flex justify-center">
                        <v-img :src="item.icon" width="120" class="flex-grow-0" height="120">
                          <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      size="80"
                                      color="red lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                          </template>
                        </v-img>
                      </div>
                      <v-card-title class="font-size-20 justify-center py-3" v-text="item.title"></v-card-title>
                      <div class="d-flex justify-center">
                        <v-card-text class="font-size-14 text-align-center justify-center py-0" style="color:#666;width:266px" v-text="item.content"></v-card-text>
                      </div>
                    </div>
                </v-card>
          </v-col>
        </v-row>

      <!-- <v-card color="" flat class="pa-2 mb-12" >
         <v-row  class="justify-center" align='center'>
           <v-col v-for='(item,ind) in data.ARadvantage.ProductCard' :key='ind' cols='3'>
             <v-img :src='item.icon' width='120' style="top:38px;margin:-20px auto;z-index: 99"></v-img>
             <v-card height='300px' width="240" class="ProductCard" flat>
               <v-img :src='item.img' height='300px' aspect-ratio="1.4" >
                <v-card-title class="font-size-24 font-weight-bold justify-center mt-11">{{item.title}}</v-card-title>
                <v-card-text class="font-size-14" style="color:#666">{{item.content}}</v-card-text>
               </v-img>
             </v-card>
           </v-col>
         </v-row>
      </v-card> -->
    </v-container>
  </v-app>
</template>

<script>
import lavData from "@/static/lavData.js";
import qs from "qs";
let _this;
export default {
  name: "Home",
  components: {},
  data() {
    return {
      data: lavData.ARHome,
      advantageTab: null,
      showDialog: false,
      tab: null,
      urlLink: window.location.host === 'wmy.ophyer.com' ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN
    };
  },
  created() {
    let _this = this;
    document.addEventListener("click", () => {
      _this.showDialog = false;
    });
  },
  mounted() {
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
    },
    handleBannerBtn() {
      window.open(this.urlLink.split(",")[3]+'/indexService?source=gwar', '_blank')
    },
    goCaseDetail: (item) => {
      let query = {
        source: "qita",
        anli: item.self,
      };
      let url = "/CaseDetail?" + qs.stringify(query);
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.lavbg {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
  background-image: url("../../assets/images/InvestmentPromotion/bg.png");
}
.tabImg{
    border-radius: 30px;
    margin-right: 87px;
  }
  .itemStyle{
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .tabTitle{
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .tabNew{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
}
::v-deep .warp1{
  .theme--light.v-sheet,.theme--light.v-card,.theme--light.v-tabs-items,.theme--light.v-tabs > .v-tabs-bar{
    background-color:unset !important;
    border-color: unset !important
  } 
}
::v-deep .warp3{
  .public-title{
    margin-top: 25px;
  }
}
::v-deep .warp4{
  .public-title{
    margin-top: 45px;
  }
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
    content: "";
    background-image: url("./../../assets/images/home/case-icon.png");
    background-size: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 16px;
    right: 10px;
  }
}
.sizeStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ProductCard{
    background: rgba(206, 206, 206, 0);
    border: 1px solid #E9E9E9;
    border-radius: 30px 30px 0px 0px;
    margin: 0 auto;
    &:hover{
      border: 1px solid #BED3FF;
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
.box_two {
    width: 1024px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .right{
      img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
      }
    }
   .left {
     text-align: start;
     padding-left: 26px;
     width: 400px;
     //font-family: SourceHanSansCN-Regular;
     margin-right: 98px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       margin: 10px 0;
       font-size: 14px !important;
       color: #666;
       &:last-child{
         margin:  0;
       }
     }
     .text {
       font-size: 14px;
       line-height: 23px;
     }
   }
}
::v-deep .box {
    width: 1024px;
    margin: 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left{
      width: 400px;
      margin-right: 157px;
      margin-left: -29px;
    }
    img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
    }
    .v-card__subtitle, .v-card__text, .v-card__title{
      padding: 10px;
    }
    .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       font-size: 14px !important;
       color: #666;
     }
     .text {
       font-size: 14px;
       line-height: 23px;
     }
}
</style>
